import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { PiDownloadSimple } from "react-icons/pi";
import './get_file.css';
import { sendLogsToCloudWatch } from '../../cloudwatch';
import { withAuthenticator } from '@aws-amplify/ui-react';

const DownloadFile = ({ signOut, user }) => {
    const [listObjectUrls, setListObjectUrls] = useState([]);
    const [summaryObjectUrls, setSummaryObjectUrls] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchItemsAndObjectUrls = async (username) => {
            try {
                const itemList = await Storage.list('output/', { level: 'private' });
                const summaryItemList = await Storage.list('output_summary/', { level: 'private' });
                
                const sortByTimestamp = (a, b) => {
                    const getTimestamp = (filename) => {
                        const match = filename.match(/_(\d{14})/);
                        return match ? match[1] : '';
                    };
                    return getTimestamp(a.key).localeCompare(getTimestamp(b.key));
                };

                const sortedItemList = itemList.sort(sortByTimestamp);
                const sortedSummaryItemList = summaryItemList.sort(sortByTimestamp);

                const listUrls = await Promise.all(sortedItemList.map(async (item) => {
                    const url = await Storage.get(item.key, { level: 'private' });
                    return { url, filename: item.key.split('/').pop() };
                }));
                setListObjectUrls(listUrls);
                
                const summaryUrls = await Promise.all(sortedSummaryItemList.map(async (item) => {
                    const url = await Storage.get(item.key, { level: 'private' });
                    return { url, filename: item.key.split('/').pop() };
                }));
                setSummaryObjectUrls(summaryUrls);

                const urlListLog = [...listUrls, ...summaryUrls].map(obj => `[DownloadPage]Filename: ${obj.filename}`).join('\n');
                await sendLogsToCloudWatch(['[DownloadPage]Successfully fetched items and URLs', urlListLog], username);
            }
            catch (error) {
                console.error('Error fetching items and object URLs:', error);
                await sendLogsToCloudWatch([`Error fetching items and object URLs: ${error.message}`], username);
            }
            finally {
                setLoading(false);
            }
        };

        if (user && user.username) {
            fetchItemsAndObjectUrls(user.username);
        } else {
            setLoading(false);
        }
    }, [user]);

    const handleDownloadClick = async (filename) => {
        try {
            await sendLogsToCloudWatch([`[DownloadPage]downloaded: ${filename}`], user.username);
        } catch (error) {
            console.error('Error logging download event:', error);
        }
    };

    return (
        <div className="download_area">
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    {listObjectUrls.length > 0 && (
                        <div className='nomal_area'>
                            <ul>
                                {listObjectUrls.map((object, index) => (
                                    <li key={index}>
                                        <a href={object.url} download={object.filename} onClick={() => handleDownloadClick(object.filename)}>
                                            {object.filename}<PiDownloadSimple />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {summaryObjectUrls.length > 0 && (
                        <div className='summary_area'>
                            <ul>
                                {summaryObjectUrls.map((object, index) => (
                                    <li key={index}>
                                        <a href={object.url} download={object.filename} onClick={() => handleDownloadClick(object.filename)}>
                                            {object.filename}<PiDownloadSimple />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {(listObjectUrls.length === 0 && summaryObjectUrls.length === 0) && (
                        <div className='both_none'>
                            <div className='nomal_area'>
                                <p>ダウンロード可能なファイルはありません</p>
                            </div>
                            <div className='summary_area'>
                                <p>ダウンロード可能なファイルはありません</p>
                            </div>
                        </div>
                    )}

                    {(listObjectUrls.length === 0 && summaryObjectUrls.length > 0) && (
                        <div className='nomal_area'>
                            <p>ダウンロード可能なファイルはありません</p>
                        </div>
                    )}

                    {(listObjectUrls.length > 0 && summaryObjectUrls.length === 0) && (
                        <div className='summary_area'>
                            <p>ダウンロード可能なファイルはありません</p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default withAuthenticator(DownloadFile);