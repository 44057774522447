import React from 'react';
import { Auth } from 'aws-amplify';
import { PiWechatLogoBold } from "react-icons/pi";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { withRouter } from 'react-router-dom';
import Burger from '../Burger/burger.jsx'
import './header.css'
import { sendLogsToCloudWatch } from '../../cloudwatch';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null
        };
    }

    componentDidMount() {
        this.getCurrentUser();
    }

    async getCurrentUser() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            this.setState({ user });
        } catch (error) {
            console.error('Error getting current user:', error);
        }
    }

    async handleSignOut() {
        try {
            const { user } = this.state;
            if (user) {
                // ログアウトボタン押下時にクラウドウォッチにログを送信
                await sendLogsToCloudWatch(['[Header]User clicked logout button'], user.username);
            }
            await Auth.signOut({ global: true });
            // リダイレクト先のURLを指定
            window.location.href = '/';
        } catch (error) {
            console.error('Error signing out:', error);
            if (this.state.user) {
                await sendLogsToCloudWatch([`[Header]Error signing out: ${error.message}`], this.state.user.username);
            }
        }
    };

    render() {
        return (
            <div className='header_area'>
                <div className='header_burger_area'>
                    <Burger />
                </div>
                <div className='header_logo_area'>
                    <PiWechatLogoBold className="header_logo"/>
                </div>
                <h1 className='header_title'>TestSite</h1>
                <button className="logout_button" onClick={this.handleSignOut.bind(this)}>
                    <RiLogoutBoxRLine className="logout_icon" />
                </button>
            </div>
        );
    }
}

export default withRouter(Header);