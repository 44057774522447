import React, { useEffect, useRef } from 'react';
import './chat.css';

function ChatArea({ messages }) {
  const chatRef = useRef(null);

  // メッセージが更新されるたびにスクロールを下に移動する
  useEffect(() => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [messages]);

  return (
    <div className="chat_area" ref={chatRef}>
      {messages.map((message, index) => (
        <div key={index} className={`message ${message.from === 'user' ? 'user_message' : 'bot_message'}`}>
          <p>{message.text}</p>
        </div>
      ))}
    </div>
  );
}

export default ChatArea;