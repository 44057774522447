import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { PiGhostThin } from "react-icons/pi";
import { TbHandFinger } from "react-icons/tb";
import Typewriter from '../../components/Typewriter/typewriter';
import Header from '../../components/Header/header';
import Burger from '../../components/Burger/burger';
import "@aws-amplify/ui-react/styles.css";
import './top_page.css';
import { Amplify } from 'aws-amplify'
import awsExports from '../../aws-exports';
import { sendLogsToCloudWatch } from '../../cloudwatch';

Amplify.configure(awsExports);

const TopPage = ({ signOut, user }) => {
    const handleLinkClick = async (event, linkName) => {
        //デフォルトのリンクを無効化
        event.preventDefault();
    
        await sendLogsToCloudWatch([`[TopPage]linkClick: ${linkName}`],user.username);
    
        //ログ出力後にリンク先に遷移
        window.location.href = linkName;
    };

    return (
    <div>
        <Header />
        <Burger signOut={signOut} user={user}/>
        <main>
            <div className="user_name_area">
                <div className="pack_man"></div>
                <h3 className='user_name'><Typewriter text= {'Hello! '+user.username} /></h3>
            </div>
            <div className='link_area'>
                <div>
                    <a href="/chat" onClick={(e) => handleLinkClick(e, 'chat')}><PiGhostThin className='link_icon_inky'/></a>
                    <p className='finger_icon_area'>チャットページは<TbHandFinger />をクリック</p>
                </div>
                <div>
                    <a href="/upload" onClick={(e) => handleLinkClick(e, 'upload')}><PiGhostThin className='link_icon_clyde'/></a>
                    <p className='finger_icon_area'>アップロードページは<TbHandFinger />をクリック</p>
                </div>
                <div>
                    <a href="/download" onClick={(e) => handleLinkClick(e, 'download')}><PiGhostThin className='link_icon_pinky'/></a>
                    <p className='finger_icon_area'>ダウンロードページは<TbHandFinger />をクリック</p>
                </div>
            </div>   
        </main>
    </div>
    );
}
export default withAuthenticator(TopPage);
