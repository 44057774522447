import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import './burger.css';
import { sendLogsToCloudWatch } from '../../cloudwatch'; // Import the sendLogsToCloudWatch function

class Burger extends React.Component {
  handleLinkClick = async (event, linkName, href) => {
    event.preventDefault();

    const { user } = this.props;

    try {
      // ログ出力
      await sendLogsToCloudWatch([`[Burger]User clicked on link: ${linkName}`], user.username);
      window.location.href = href;
    } catch (error) {
      console.error('[Burger]Error logging link click:', error);
    }
  };

  handleSignOut = async (event) => {
    event.preventDefault();

    const { user, signOut } = this.props;

    try {
      // ログ出力
      await sendLogsToCloudWatch([`[Burger]User signed out`], user.username);


      await signOut();

      // ログアウト後のURLを/にする
      window.location.href = '/';
    } catch (error) {

      console.error('[Burger]Error logging sign out:', error);
    }
  };

  render() {
    return (
      <Menu width={'150px'} right>
        <a id="home" className="menu-item" href="/top" onClick={(e) => this.handleLinkClick(e, 'Home', '/top')}>Home</a>
        <a id="chat" className="menu-item" href="/chat" onClick={(e) => this.handleLinkClick(e, 'Chat', '/chat')}>Chat</a>
        <a id="upload" className="menu-item" href="/upload" onClick={(e) => this.handleLinkClick(e, 'Upload', '/upload')}>Upload</a>
        <a id="download" className="menu-item" href="/download" onClick={(e) => this.handleLinkClick(e, 'Download', '/download')}>Download</a>
        <button className="menu-item" onClick={this.handleSignOut}>Sign out</button>
      </Menu>
    );
  }
}
export default Burger;
