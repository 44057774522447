import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Upload from '../../components/Upload/upload';
import Header from '../../components/Header/header';
import Burger from '../../components/Burger/burger';
import Breadcrumbs from '../../components/Breadcrumbs/bread_crumb_upload';
import { PiGhostThin } from "react-icons/pi";
import './upload_page.css'

const UploadPage = ({ signOut,user }) => {
    return (
        <div>
            <Header />
            <Burger signOut={signOut} user={user} />
            <main className='upload_headline'>
                <Breadcrumbs />
                <div className='upload_title'>
                    <PiGhostThin className='ghost_clyde'/><h1>アップロードページです</h1><PiGhostThin className='ghost_clyde'/>
                </div>
                <div className='attention_area'>
                    <p>※ファイル名は英数字、ピリオド、ハイフン、アンダーバーのみが使用できます。</p>
                    <p>※選択できるファイルは1つまでです。</p>
                </div>
                <Upload />
            </main>
        </div>
    );
};

export default withAuthenticator(UploadPage);

