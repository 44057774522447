import React from 'react';
import { Link } from 'react-router-dom';
import './bread_crumb_chat.css'

function Breadcrumbs() {
  return (
    <nav aria-label="breadcrumb_nav">
      <ol className="breadcrumb_nav">
        <li className="breadcrumb_item"><Link to="/top">TOP</Link></li>
        <li className="breadcrumb_item active" aria-current="page">チャットページ</li>
      </ol>
    </nav>
  );
}

export default Breadcrumbs;