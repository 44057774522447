/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "e9ae103965c44c75b7695cf4b1d9c768",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "e9ae103965c44c75b7695cf4b1d9c768",
            "region": "us-east-1"
        }
    },
    "aws_cognito_identity_pool_id": "us-east-1:fdd37692-6d70-49ea-85ad-83d7d15160c9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_YKOZiGLrh",
    "aws_user_pools_web_client_id": "1q5b6nilk6cgmnqt93bvgn1efj",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "testtexttranscriptio-20240703173014-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d21o1cok5zp7dw.cloudfront.net",
    "aws_user_files_s3_bucket": "testtexttranscriptio73b67dda7fe848649c562ab8f49602b1-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
