import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import GetFile from '../../components/Download/get_file';
import Header from '../../components/Header/header';
import Burger from '../../components/Burger/burger';
import Breadcrumbs from '../../components/Breadcrumbs/bread_crumb_download';
import { PiGhostThin } from "react-icons/pi";
import './download_page.css'

const DownloadPage = ({ signOut,user })=> {
    return (
        <div>
            <Header />
            <Burger signOut={signOut} user={user} />
            <main className='download_headline'>
                <Breadcrumbs />
                <div className='download_title'>
                    <PiGhostThin className='ghost_blinky'/><h1>ダウンロードページです</h1><PiGhostThin className='ghost_blinky'/>
                </div>
                <div className='attention_area'>
                    <p>※反映までに少し時間がかかります。表示されない場合は再読み込みをしてください。</p>
                </div>
                <GetFile />
            </main>
        </div>
    );
};

export default withAuthenticator(DownloadPage);
