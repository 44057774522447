import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Hub, Auth } from 'aws-amplify';
import Header from '../../components/Header/header';
import Burger from '../../components/Burger/burger';
import Breadcrumbs from '../../components/Breadcrumbs/bread_crumb_chat';
import ChatArea from '../../components/Chat/chat';
import './chat_page.css';
import { LuSendHorizonal } from 'react-icons/lu';
import { sendLogsToCloudWatch } from '../../cloudwatch';

function ChatPage({ signOut, user }) {
    const [inputText, setInputText] = useState('');
    const [messages, setMessages] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [selectedOption, setSelectedOption] = useState('anthropic.claude-instant-v1');
    const history = useHistory();

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (isSending || !inputText.trim()) return;

        setIsSending(true);

        try {
            const newMessages = [...messages, { text: inputText, from: 'user' }];
            setMessages(newMessages);
            setInputText('');
        
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            console.log('Full session:', session);
            console.log('ID Token:', token);
        
            const url = `https://u0axsbo5w4.execute-api.us-east-1.amazonaws.com/dev/rag?question=${encodeURIComponent(inputText)}&sessionid=None&option=${selectedOption}`;
            
            console.log('Request URL:', url);
            console.log('Authorization Token:', token);

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json',
                },
            });
        
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Response status:', response.status);
                console.error('Response headers:', Object.fromEntries(response.headers));
                console.error('Error text:', errorText);
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }
            const data = await response.json();
            console.log('API Response:', data);
            console.log('選択されたオプション:', selectedOption);
            setMessages([...newMessages, { text: data.answer, from: 'bot' }]);

            await sendLogsToCloudWatch([
                `User sent message: ${inputText}`,
                `API query: ${url}`
            ], user.username);
        } catch (error) {
            console.error('Detailed error:', error);
            await sendLogsToCloudWatch([`Error fetching data: ${error.message}`], user.username);
            setMessages([...messages, { text: `An error occurred: ${error.message}`, from: 'bot' }]);
        } finally {
            setIsSending(false);
        }
    };

    useEffect(() => {
        const handleAuthStateChange = (state) => {
            if (state === 'signedout') {
                history.push('/login');
            }
        };

        const subscription = Hub.listen('auth', handleAuthStateChange);

        return () => {
            Hub.remove('auth', handleAuthStateChange);
        };
    }, [history]);

    return (
        <div>
            <Header />
            <Burger signOut={signOut} user={user} />
            <main>
                <Breadcrumbs />
                <div className='radio_chat_area'>
                    <div className="radio_area">
                        <div className='radio_area_p'>
                            <p>モデルを選んで</p>
                            <p>ください</p>
                        </div>
                        <div className='radio_input_area'>
                            <input type="radio" name="Claude" id="select3" value="anthropic.claude-instant-v1" checked={selectedOption === 'anthropic.claude-instant-v1'} onChange={handleOptionChange} /><label htmlFor="select3">Claude Instant</label>
                            <input type="radio" name="Claude" id="select1" value="anthropic.claude-v2:1" onChange={handleOptionChange} /><label htmlFor="select1">Claude:v2.1</label>
                            <input type="radio" name="Claude" id="select2" value="anthropic.claude-v2" onChange={handleOptionChange} /><label htmlFor="select2">Claude:v2</label>
                            <input type="radio" name="Claude" id="select4" value="anthropic.claude-3-5-sonnet-20240620-v1:0" onChange={handleOptionChange} /><label htmlFor="select4">Claude:3.5</label>
                        </div>
                    </div>
                    <div className="chat_container">
                        <ChatArea messages={messages} />
                    </div>
                </div>
                <div className='form_area_all'>
                    <form className="chat_form_area" onSubmit={handleSubmit}>
                        <div className="chat_input_area">
                            <textarea 
                                className="chat_input_text" 
                                type="text" 
                                value={inputText} 
                                placeholder='Please enter your text...' 
                                onChange={(e) => setInputText(e.target.value)}
                                onKeyDown={(e) => {
                                    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                                        e.preventDefault();
                                        handleSubmit(e);
                                    }
                                }}
                            />
                            <button className="chat_submit_button" type="submit" disabled={isSending}>
                                <LuSendHorizonal />
                            </button>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    );
}

export default withAuthenticator(ChatPage);
