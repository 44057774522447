import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import TopPage from './pages/TopPage/top_page';
import ChatPage from './pages/ChatPage/chat_page';
import UploadPage from './pages/UploadPage/upload_page';
import DownloadPage from './pages/DownloadPage/download_page';
import './styles/styles.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={TopPage} />
        <Route path="/top" component={TopPage} />
        <Route path="/chat" component={ChatPage} />
        <Route path="/upload" component={UploadPage} />
        <Route path="/download" component={DownloadPage} />
      </Switch>
    </Router>
  );
}

export default App;

