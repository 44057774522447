import { CloudWatchLogsClient, PutLogEventsCommand, CreateLogStreamCommand, DescribeLogStreamsCommand } from '@aws-sdk/client-cloudwatch-logs';

const region = process.env.REACT_APP_AWS_REGION;
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const logGroupName = process.env.REACT_APP_LOG_GROUP_NAME;

console.log('AWS Region:', region);
console.log('AWS Access Key ID:', accessKeyId ? 'Provided' : 'Missing');
console.log('AWS Secret Access Key:', secretAccessKey ? 'Provided' : 'Missing');
console.log('Log Group Name:', logGroupName);

if (!region || !accessKeyId || !secretAccessKey || !logGroupName) {
    console.error('Missing environment variables for AWS configuration');
}

const cloudWatchLogsClient = new CloudWatchLogsClient({
    region,
    credentials: {
        accessKeyId,
        secretAccessKey
    }
});

const checkLogStreamExists = async (logStreamName) => {
    try {
        const describeLogStreamsCommand = new DescribeLogStreamsCommand({
            logGroupName,
            logStreamNamePrefix: logStreamName
        });
        const response = await cloudWatchLogsClient.send(describeLogStreamsCommand);
        return response.logStreams.some(logStream => logStream.logStreamName === logStreamName);
    } catch (error) {
        console.error('Error checking log stream existence:', error);
        return false;
    }
};

export const sendLogsToCloudWatch = async (messages, username) => {
    const logStreamName = `${username}`;

    try {
        const logStreamExists = await checkLogStreamExists(logStreamName);

        if (!logStreamExists) {
            await cloudWatchLogsClient.send(new CreateLogStreamCommand({
                logGroupName,
                logStreamName
            }));
        }

        const logEvents = messages.map((message, index) => ({
            message,
            timestamp: new Date().getTime() + index // Unique timestamps
        }));

        const putLogEventsCommand = new PutLogEventsCommand({
            logGroupName,
            logStreamName,
            logEvents
        });

        await cloudWatchLogsClient.send(putLogEventsCommand);
    } catch (error) {
        console.error('Error sending logs to CloudWatch:', error);
    }
};



