import React, { useState, useEffect } from 'react';
import { Amplify, Storage } from 'aws-amplify';
import awsmobile from '../../aws-exports';
import { IoCloudUpload } from "react-icons/io5";
import './upload.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { sendLogsToCloudWatch } from '../../cloudwatch';


Amplify.configure(awsmobile);

const Upload = ({ user }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);

    const handleFileInputChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileName = file.name;
            const isValidFileName = /^[a-zA-Z0-9-_.]+$/.test(fileName);
            if (!isValidFileName) {
                alert('ファイル名には、ハイフン、ピリオド、アンダーバー、半角英数字のみが使用できます。');
                return;
            }
            setSelectedFile(file);
            await sendLogsToCloudWatch([`[Upload]selected: ${file.name}`], user.username);
        } else {
            setSelectedFile(null);
        }
    };

    const handleFileDrop = async (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            const fileName = file.name;
            const isValidFileName = /^[a-zA-Z0-9-_.]+$/.test(fileName);
            if (!isValidFileName) {
                alert('ファイル名には、ハイフン、ピリオド、アンダーバー、半角英数字のみが使用できます。');
                return;
            }
            setSelectedFile(file);
            await sendLogsToCloudWatch([`[Upload]selected: ${file.name}`], user.username);
        } else {
            setSelectedFile(null);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleFileUpload = async () => {
        if (!selectedFile || uploading) {
            console.log('No file selected or already uploading');
            return;
        }
    
        setUploading(true);
    
        try {
            const result = await Storage.put('input/' + selectedFile.name, selectedFile, {
                level: 'private',
                contentType: selectedFile.type
            });
            console.log('Input Succeeded: ', result);
    
            setUploaded(true);
            await sendLogsToCloudWatch([`[Upload]File uploaded: ${selectedFile.name}`], user.username);
        } catch (error) {
            console.log('Error: ', error);
            await sendLogsToCloudWatch([`[Upload]File upload error: ${error.message}`], user.username);
        } finally {
            setUploading(false);
        }
    };

    const handleClearSelection = () => {
        setSelectedFile(null);
    };

    useEffect(() => {
        let timeout;
        if (uploaded) {
            timeout = setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
        return () => clearTimeout(timeout);
    }, [uploaded]);

    return (
        <div>
            {selectedFile ? (
                <div className='drop_area' onDrop={handleFileDrop} onDragOver={handleDragOver}>
                    <button onClick={handleClearSelection} className='canceling_button'>×</button>
                    選択されたファイル: {selectedFile.name}
                </div>
            ) : (
                <label htmlFor="fileInput">
                    <div className='drop_area' onDrop={handleFileDrop} onDragOver={handleDragOver}>
                        <div className='drop_area_contents'>
                            <IoCloudUpload />
                            <p className='drop_area_p'>ドラッグ＆ドロップ<br></br>またはクリックして選択</p>
                        </div>
                    </div>
                </label>
            )}
            <input id="fileInput" type="file" style={{ display: 'none' }} onChange={handleFileInputChange} />
            <button onClick={handleFileUpload} disabled={!selectedFile || uploading || uploaded} className='sending_button'>送信</button>
            {uploading && <p>アップロード中...</p>}
            {!uploading && uploaded && <p>完了しました。3秒後にリロードします。</p>}
        </div>
    );
};

export default withAuthenticator(Upload);

